import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Product } from 'src/app/core/models/product.interface';
import { AddShoppingService } from 'src/app/services/add-shopping.service';

@Component({
  selector: 'app-form-add-shopping',
  templateUrl: './form-add-shopping.component.html',
  styleUrls: ['./form-add-shopping.component.scss']
})
export class FormAddShoppingComponent implements OnInit {

  @Input() product: Product = null;
  @Output() OnAdd = new EventEmitter<void>();
  public total = 0;
  private quantityProduct = 1;
  public comment = '';

  constructor(private addShoppingService: AddShoppingService) { }

  ngOnInit(): void {
    this.total = this.product?.price;
  }

  onQuantity(quantity: number): void {
    this.total = (this.product?.price || 0) * quantity;
    this.quantityProduct = quantity;
  }

  addCart(): void {
    this.addShoppingService.addCart(this.product, this.total, this.quantityProduct, this.comment);
    this.OnAdd.emit();
  }
}
