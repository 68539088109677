<div class="col-imagen-mobile"></div>
<div class="container-form">
    <h2 class="title-form">{{ product.nameProduct }}</h2>
    <div class="content-form">
        <div style="width: 40vh;">
            <h2 style="margin-bottom: 0px !important;">Descripcion:</h2>
            <p style="overflow-wrap: break-word;">{{ product.descriptionProduct }}</p>
            <h2 style="margin-bottom: 0px !important;">Precio Unitario</h2>
            <p>{{product.price | currency:'CLP':'symbol-narrow'}}</p>
        </div>

        <form>
            <div style="width: 100%; text-align: center; display: flex; flex-direction: column; align-items: center;">
                <mat-label class="header-label">CANTIDAD</mat-label>
                <app-stepper-quantity (OnQuantity)="onQuantity($event)"></app-stepper-quantity>
                <br>
                <mat-label class="header-label">NOTAS ADICIONALES</mat-label>
            </div>

            <mat-form-field appearance="outline" class="full-input">
                <textarea placeholder="Aquí puedes escribir tus notas..." matInput rows="8" style="resize:none" [(ngModel)]="comment" [ngModelOptions]="{standalone: true}"></textarea>
            </mat-form-field>
        </form>
    </div>
    <div class="action-button">
        <div class="col-action">Total: {{ total | currency:'CLP':'symbol-narrow'}}</div>
        <div class="col-action" style="display:flex; flex-direction:row-reverse;">
            <button mat-button class="modal-buttom" style="margin-left: 10px;" (click)="addCart()">Agregar</button>
            <button mat-button class="modal-buttom button-cancel" color="warn" mat-dialog-close>Cancelar</button>
        </div>
    </div>
</div>