import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of } from 'rxjs';
import { ModalAddShoppingComponent } from './components/modal-add-shopping/modal-add-shopping.component';
import { ModalShoppingComponent } from './components/modal-shopping/modal-shopping.component';

import {
  BreakpointObserver,
  Breakpoints,
  BreakpointState
} from '@angular/cdk/layout';
import { Category } from '../core/models/product.interface';
import { AddShoppingService } from '../services/add-shopping.service';
import { Shopping } from '../core/models/shopping.interface';
import { WhatsappService } from '../services/whatsapp.service';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public dataHeader = {
    sourceLogoHeader: '/assets/images/logo.png',
    sourceLogoBanner: '/assets/images/logo.png',
    nameCompany: 'ByteBurguers & Más',
    descriptionCompany: 'Ven a comer o pide delivery con nuestros ricos productos en el menú.',
    bannerUrl: '/assets/images/portada2.png',
    bgColor: '#151515',
    link: 'https://gitlab-dev.byteservices.cl/'
  };

  public dataFooter = {
    dayInitial: 'Martes',
    dayFinal: 'Domingo',
    timeOpen: '13:00',
    timeClose: '22:00',
    address: {
      city: 'Santiago',
      comuna: 'San Miguel',
      street: 'Curiñanca',
      number: '919'
    },
    phoneNumber: '959866499',
    urlFacebook: 'www.facebook.com',
    urlInstagram: 'www.instagram.com',
    urlTwitter: 'www.twitter.com',
    sourceLogoHeader: '/assets/images/logo.png'
  };

  public dataCard: Observable<Category[]> = of([
    {
      id: 1,
      title: 'Hamburguesas',
      products: [
        {
          idProduct: 1,
          nameProduct: 'La Pobre',
          descriptionProduct: 'Hamburguesa casera, huevo revuelto, cebolla morada y papas fritas',
          price: 4300,
          sourceImageProduct: 'assets/images/cheeseburger.png',
          type: 'Hamburguesas'
        },
        {
          idProduct: 2,
          nameProduct: 'Clasica',
          descriptionProduct: 'Hamburguesa casera, Lechuga, Tomate, Cebolla y Mayonesa',
          price: 3500,
          sourceImageProduct: 'assets/images/cheeseburger.png',
          type: 'Hamburguesas'
        },
        {
          idProduct: 3,
          nameProduct: 'Italiana',
          descriptionProduct: 'Hamburguesa casera, Palta, Tomate y Mayonesa',
          price: 4500,
          sourceImageProduct: 'assets/images/cheeseburger.png',
          type: 'Hamburguesas'
        }
      ]
    },
    {
      id: 2,
      title: 'Completos',
      products: [
        {
          idProduct: 4,
          nameProduct: 'Italiana',
          descriptionProduct: '',
          price: 2700,
          sourceImageProduct: 'assets/images/fast-food.png',
          type: 'Completos'
        },
        {
          idProduct: 5,
          nameProduct: 'Dinamica',
          descriptionProduct: '',
          price: 2000,
          sourceImageProduct: 'assets/images/fast-food.png',
          type: 'Completos'
        },
        {
          idProduct: 6,
          nameProduct: 'A lo pobre',
          descriptionProduct: '',
          price: 2500,
          sourceImageProduct: 'assets/images/fast-food.png',
          type: 'Completos'
        }
      ]
    },
    {
      id: 3,
      title: 'Milanesas',
      products: [
        {
          idProduct: 7,
          nameProduct: 'La Napolitana',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Tomate al orégano o salsa de tomate casera, exquisito queso mozzarella fundido, Jamón y Salsa a elección (Mila, BBQ, Savora)',
          price: 4500,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 8,
          nameProduct: 'La Italiana',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Tomate, Palta y Salsa Mila (Mayonesa casera con orégano)',
          price: 5000,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 9,
          nameProduct: 'La Chilena',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Tomate al orégano, Porotos verdes, Ají verde y Salsa a elección (Mila, BBQ, Savora)',
          price: 5000,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 10,
          nameProduct: 'La Argentina',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Tomate al orégano, Lechuga, Jamón, Huevo frito y Exquisito queso mozzarella fundido',
          price: 5000,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 11,
          nameProduct: 'La Cordobesa',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Tomate al orégano, Tocino, Lechuga, Exquisito queso mozzarella fundido y Salsa a elección (Mila, BBQ, Savora)',
          price: 5000,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 12,
          nameProduct: 'La Mendocina',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Cebolla caramelizada, Exquisito queso mozzarella fundido y Salsa a elección (Mila, BBQ, Savora)',
          price: 4500,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 13,
          nameProduct: 'La Mendocina',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Tocino, Queso cheddar, Cebolla caramelizada, Champiñones salteados y Salsa a elección (Mila, BBQ, Savora)',
          price: 5300,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        },
        {
          idProduct: 14,
          nameProduct: 'La Porteña',
          descriptionProduct: 'Base bife de carne apanado o suprema de pollo, Queso cheddar, Choclo, Champiñones salteados y Salsa a elección (Mila, BBQ, Savora)',
          price: 5000,
          sourceImageProduct: 'assets/images/ciabatta.png',
          type: 'Milanesas'
        }
      ]
    },
    {
      id: 4,
      title: 'Bebidas',
      products: [
        {
          idProduct: 15,
          nameProduct: 'Coca cola',
          descriptionProduct: 'Lata',
          price: 1000,
          sourceImageProduct: 'assets/images/soda-can.png',
          type: 'Bebidas'
        },
        {
          idProduct: 16,
          nameProduct: 'Sprite',
          descriptionProduct: 'Lata',
          price: 1000,
          sourceImageProduct: 'assets/images/soda-can.png',
          type: 'Bebidas'
        },
        {
          idProduct: 17,
          nameProduct: 'Fanta',
          descriptionProduct: 'Lata',
          price: 1000,
          sourceImageProduct: 'assets/images/soda-can.png',
          type: 'Bebidas'
        }
      ]
    }
  ]);

  favIcon: HTMLLinkElement = document.querySelector('#appIcon');

  isExtraSmall: Observable<BreakpointState> = this.breakpointObserver.observe(
    Breakpoints.XSmall
  );

  private products: Array<Shopping> = [];

  constructor(
    public dialog: MatDialog,
    private readonly breakpointObserver: BreakpointObserver,
    private addShoppingService: AddShoppingService,
    private whatsappService: WhatsappService,
    private titleService: Title
  ) { }

  ngOnInit(): void {
    this.favIcon.href = this.dataHeader.sourceLogoHeader;
    this.titleService.setTitle(`Menu: ${this.dataHeader.nameCompany}`);
    this.products = [...this.addShoppingService.getProducts()];
  }

  actionAdd(event: any) {

    let modal = null;
    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (modal !== null) {
        this.dialog.closeAll();
      }

      if (size.matches) {
        modal = this.dialog.open(ModalAddShoppingComponent, {
          width: '900px',
          panelClass: 'shopping-dialog-container',
          disableClose: true,
          data: event,
          maxWidth: '100vw'
        });
        modal.updateSize('100vw', '100vh');
      } else {
        modal = this.dialog.open(ModalAddShoppingComponent, {
          width: '900px',
          panelClass: 'shopping-dialog-container',
          disableClose: true,
          data: event
        });
      }
    });

    modal.afterClosed().subscribe(action => {
      smallDialogSubscription.unsubscribe();
      if (action) {
        this.products = [...this.addShoppingService.getProducts()];
      }
    });
  }

  actionShopping() {
    let modal = null;

    const smallDialogSubscription = this.isExtraSmall.subscribe(size => {
      if (modal !== null) {
        this.dialog.closeAll();
      }

      if (size.matches) {
        modal = this.dialog.open(ModalShoppingComponent, {
          width: '900px',
          panelClass: 'shopping-dialog-container',
          disableClose: true,
          data: {},
          maxWidth: '100vw'
        });
        modal.updateSize('100vw', '100vh');
      } else {
        modal = this.dialog.open(ModalShoppingComponent, {
          width: '900px',
          panelClass: 'shopping-dialog-container',
          disableClose: true,
          data: {}
        });
      }
    });

    modal.afterClosed().subscribe(action => {
      smallDialogSubscription.unsubscribe();
      this.products = [...this.addShoppingService.getProducts()];
      if (action) {
        this.products = [...this.addShoppingService.getProducts()];
      }
    });
  }

  get totalShopping(): number {
    return this.products.reduce((acc, current) => current.total + acc, 0);
  }

  onShared() {
    const template = this.whatsappService.builderMessageShared(this.dataHeader.link);
    const link = `https://api.whatsapp.com/send?phone=56933201655&text=${this.whatsappService.convertUrl(template)}`;
    window.open(link, '_blank');
  }
}
