import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-modal-add-shopping',
  templateUrl: './modal-add-shopping.component.html',
  styleUrls: ['./modal-add-shopping.component.scss']
})
export class ModalAddShoppingComponent implements OnInit {

  public product: any = {};

  constructor(
    private dialogRef: MatDialogRef<ModalAddShoppingComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
  ) { }

  ngOnInit(): void {
    this.product = {...this.data};
  }

  closeModal(): void {
    this.dialogRef.close(true);
  }
}
