import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private toastr: ToastrService) { }

  success(msj: string): void {
    this.toastr.success(msj, 'Completado');
  }

  error(msj: string): void {
    this.toastr.error(msj, 'Error');
  }

  info(msj: string): void {
    this.toastr.info(msj, 'Info');
  }

  warning(msj: string): void {
    this.toastr.warning(msj, 'Precaución');
  }

  clear() {
    this.toastr.clear();
  }
}
