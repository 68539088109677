<header [ngStyle]="{'background-image': 'url( ' + dataHeader.bannerUrl + ')' }" (scroll)="onWindowScroll()">
    <nav *ngIf="headerColor" class="header--logo-container" [ngStyle]="{'background-color': headerColor }">
        <img *ngIf="dataHeader.sourceLogoHeader" class="header--logo" [src]="dataHeader.sourceLogoHeader"
            alt="logo empresa">
        <h6 *ngIf="dataHeader.nameCompany">{{ dataHeader.nameCompany }}</h6>
    </nav>
    <figure class="banner--container">
        <img *ngIf="dataHeader.sourceLogoBanner" class="d-none-mobile" [src]="dataHeader.sourceLogoBanner"
            alt="logo empresa">
    </figure>
    <div class="header--title-container">
        <h1>Bienvenidos</h1>
        <p>{{ dataHeader.descriptionCompany }} </p>
        <share-buttons theme="material-dark" [include]="['whatsapp', 'copy']" [showIcon]="true" [showText]="true" title="Compartir"
            [url]="dataHeader.link" description="Visita nuestro menú en:" class="header--button">
        </share-buttons>
        <!-- <button mat-stroked-button class="button-global header--button" (click)="sharedLink()">Compartir</button> -->
    </div>
</header>