import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-floating-button',
  templateUrl: './floating-button.component.html',
  styleUrls: ['./floating-button.component.scss']
})
export class FloatingButtonComponent {

  @Input() orderPrice: string;
  @Output() floatAction = new EventEmitter<void>();

  actionButton() {
    this.floatAction.emit();
  }
}
