
<mat-card class="card">
    <mat-card-content class="card__content">
        <div class="card__content--info">
            <figure>
                <img [src]="dataProduct.sourceImageProduct" alt="image food" width="110">
            </figure>
            <div class="dataProduct">
                <h3>{{ dataProduct.nameProduct }} </h3>
                <p>{{ dataProduct.descriptionProduct }} </p>
            </div>
        </div>
        <div class="card__content--action">
            <h4>{{ dataProduct.price | currency:'CLP':'symbol-narrow' }}</h4>
            <button mat-stroked-button class="button-global" (click)="add(dataProduct)"><mat-icon>add_shopping_cart</mat-icon>Agregar </button>
        </div>
    </mat-card-content>
</mat-card>
