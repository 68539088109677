import { Injectable } from '@angular/core';
import { StringBuilder } from 'typescript-string-operations';
import { Shopping } from '../core/models/shopping.interface';
import * as moment from 'moment';
import 'moment/locale/es';
import { CurrencyPipe } from '@angular/common';

moment.locale('es');

@Injectable({
  providedIn: 'root'
})
export class WhatsappService {

  constructor(private currency: CurrencyPipe) { }

  addBold(builder: StringBuilder, text: string): void {
    builder.AppendLineFormat('*{0}*', text);
  }

  addItalics(builder: StringBuilder, text: string): void {
    builder.AppendLineFormat('_{0}_', text);
  }

  addLabeled(builder: StringBuilder, text: string): void {
    builder.AppendLineFormat('~{0}~', text);
  }

  addMonospaced(builder: StringBuilder, text: string): void {
    builder.AppendLineFormat('```{0}```', text);
  }

  addNormal(builder: StringBuilder, text: string): void {
    builder.AppendLine(text);
  }

  addLine(builder: StringBuilder): void {
    builder.AppendLine('');
  }

  builderMessage(products: Array<Shopping>, formValue: any): string {
    const dateMessage = moment().format('DD-MM-YYYY H:mm');
    const builder = new StringBuilder();

    this.addBold(builder, '------------------------------------');
    this.addBold(builder, '------------------------------------');
    this.addMonospaced(builder, `Pedido: ${ dateMessage }`);
    this.addBold(builder, '------------------------------------');
    this.addBold(builder, '------------------------------------');
    this.addBold(builder, 'Nombre:');
    this.addNormal(builder, formValue.name);
    this.addBold(builder, 'Entrega:');
    this.addNormal(builder, formValue.typeDelivery);
    if (formValue.typeDelivery === 'Delivery') {
      this.addBold(builder, 'Dirección:');
      this.addNormal(builder, formValue.address);
    }
    this.addBold(builder, 'Metodo de Pago:');
    this.addNormal(builder, formValue.typePay);
    this.addLine(builder);
    if (formValue.typeDelivery === 'Delivery') {
      this.addBold(builder, 'Nota Adicional:');
      this.addNormal(builder, formValue.description);
      this.addLine(builder);
    }
    this.addBold(builder, 'Lista:');

    products.forEach(product => {
      this.addNormal(builder, `-${product.quantity} ${ product.product.type } ${product.product.nameProduct}`);
      if (product.comment) {
        this.addItalics(builder, `(${product.comment})`);
      }
      this.addLine(builder);
    });
    const total = products.reduce((acc, current) => current.total + acc , 0);

    this.addBold(builder, `Total: ${ this.currency.transform(total, 'CLP', 'symbol-narrow') }`);
    if (formValue.typePay === 'Efectivo') {
      this.addBold(builder, `Pago con: ${ this.currency.transform(formValue.amountPay, 'CLP', 'symbol-narrow')}`);
    }
    this.addBold(builder, '------------------------------------');

    return builder.ToString();
  }

  builderMessageShared(link: string): string {
    const builder = new StringBuilder();

    this.addBold(builder, 'Visita nuestro Menú en:');
    this.addNormal(builder, link);

    return builder.ToString();
  }

  convertUrl(text: string): string {
    return encodeURI(text);
  }
}
