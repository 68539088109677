import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'app-card-menu',
  templateUrl: './card-menu.component.html',
  styleUrls: ['./card-menu.component.scss']
})
export class CardMenuComponent {

  @Input() dataProduct: any = [];
  @Output() OnClickButton = new EventEmitter<any>();

  add(product: any) {
    this.OnClickButton.emit(product);
  }
}
