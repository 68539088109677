import { HostListener, Input, Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  @Input() dataHeader: any;
  @Output() OnShared = new EventEmitter<void>();
  headerColor = '';

  constructor() {}

  ngOnInit(): void {
    const color = this.dataHeader && this.dataHeader.bgColor;
    this.headerColor = `rgba(${parseInt(color.substring(1, 3), 16)}, ${parseInt(color.substring(3, 5), 16)}, ${parseInt(color.substring(5, 7), 16)}, 0)`;
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    const color = this.dataHeader && this.dataHeader.bgColor;
    const opacity = window.pageYOffset / 100;
    this.headerColor = `rgba(${parseInt(color.substring(1, 3), 16)}, ${parseInt(color.substring(3, 5), 16)}, ${parseInt(color.substring(5, 7), 16)}, ${opacity})`;
  }

  sharedLink() {
    this.OnShared.emit();
  }
}
