import { Injectable } from '@angular/core';
import { Product } from '../core/models/product.interface';
import { Shopping } from '../core/models/shopping.interface';

@Injectable({
  providedIn: 'root'
})
export class AddShoppingService {

  constructor() { }

  addCart(product: Product, total: number, quantity: number, comment: string): void {
    const productLocal = JSON.parse(localStorage.getItem('product') || '[]');
    const shopping: Shopping = { product, total, quantity, comment };
    const newProduct: Array<Shopping> = [...productLocal, shopping];
    localStorage.setItem('product', JSON.stringify(newProduct));
  }

  editCart(quantity: number, id: number): void {
    const products: Shopping[] = JSON.parse(localStorage.getItem('product') || '[]');
    const updatedData = products.map(x => (x.product.idProduct === id ? { ...x, quantity } : x));
    localStorage.setItem('product', JSON.stringify(updatedData));
  }

  removeCart(id: number): void {
    const products: Shopping[] = JSON.parse(localStorage.getItem('product') || '[]');
    const newProducts = products.filter((ele) => {
      return ele.product.idProduct !== id;
    });

    localStorage.setItem('product', JSON.stringify(newProducts));
  }

  getProducts(): Array<Shopping> {

    const products: Shopping[] = JSON.parse(localStorage.getItem('product') || '[]');
    const unique = [...new Set(products.map(item => item.product.idProduct))];

    const productUnique: Shopping[] = unique.reduce((acc, value) => {
      const oldValue = products.find(f => f.product.idProduct === value);
      const filter = products.filter(f => f.product.idProduct === value);
      const newValue = filter.reduce((accf, valuef) => accf + valuef.quantity, 0);

      return [...acc, { ...oldValue, quantity: newValue, total: (oldValue.product.price * newValue) }];
    }, []);

    localStorage.setItem('product', JSON.stringify(productUnique));

    return productUnique;
  }

  clearCart() {
    localStorage.removeItem('product');
  }
}
