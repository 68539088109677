import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StepperQuantityComponent } from './stepper-quantity/stepper-quantity.component';
import { MaterialModule } from '../material.module';
import { StepperEditQuantityComponent } from './stepper-edit-quantity/stepper-edit-quantity.component';



@NgModule({
  declarations: [StepperQuantityComponent, StepperEditQuantityComponent],
  imports: [
    CommonModule,
    MaterialModule
  ],
  exports: [
    StepperQuantityComponent,
    StepperEditQuantityComponent
  ]
})
export class SharedModule { }
