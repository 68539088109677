import { LOCALE_ID, NgModule } from '@angular/core';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { CardMenuComponent } from './card-menu/card-menu.component';
import { MaterialModule } from 'src/app/material.module';
import { FloatingButtonComponent } from './floating-button/floating-button.component';
import { ModalAddShoppingComponent } from './modal-add-shopping/modal-add-shopping.component';

// El local de la APP
import localeEs from '@angular/common/locales/es-CL';
import { ModalShoppingComponent } from './modal-shopping/modal-shopping.component';
import { FooterComponent } from './footer/footer.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormAddShoppingComponent } from './form-add-shopping/form-add-shopping.component';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

registerLocaleData(localeEs);


@NgModule({
  declarations: [
    HeaderComponent,
    CardMenuComponent,
    FloatingButtonComponent,
    FooterComponent,
    ModalAddShoppingComponent,
    ModalShoppingComponent,
    FormAddShoppingComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    ShareButtonsModule.withConfig({
      prop: {
        copy: {
          text: 'Copiar Link',
          data: {
            text: 'Copiar Link',
            successText: 'Copiado'
          }
        },
        whatsapp: {
          text: 'Compartir'
        }
      }
    }),
    ShareIconsModule
  ],
  exports: [
    HeaderComponent,
    CardMenuComponent,
    FloatingButtonComponent,
    FooterComponent
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'es-CL' }
  ]
})
export class ComponentsModule { }
