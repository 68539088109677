import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-stepper-quantity',
  templateUrl: './stepper-quantity.component.html',
  styleUrls: ['./stepper-quantity.component.scss']
})
export class StepperQuantityComponent {

  @Input() quantity = 1;
  @Output() OnQuantity = new EventEmitter<number>();

  removeQuantity(): void {
    if (this.quantity !== 1) {
      this.quantity = this.quantity - 1;
      this.OnQuantity.emit(this.quantity);
    }
  }

  addQuantity(): void {
    this.quantity = this.quantity + 1;
    this.OnQuantity.emit(this.quantity);
  }
}
