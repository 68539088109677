<footer [ngStyle]="{'background-color': footerColor }" class="footer">

  <div class="d-flex justify-content-around align-items-center">
    <div class="container-logo"> 
      <img *ngIf="dataFooter.sourceLogoHeader" class="footer--logo" [src]="dataFooter.sourceLogoHeader" alt="logo empresa">
    </div>

    <div class="data-info">
      <p> <mat-icon>event</mat-icon> {{ dataFooter.dayInitial }} a {{ dataFooter.dayFinal }} de {{ dataFooter.timeOpen }} a {{ dataFooter.timeClose }}</p>
      <p> <mat-icon>place</mat-icon> {{ dataFooter.address.street }} {{ dataFooter.address.number }}, {{ dataFooter.address.city }}. </p> 
      <p> <mat-icon>phone</mat-icon> {{ dataFooter.phoneNumber }}</p>
    </div>

    <figure class="container-social-media">
      <a *ngIf="dataFooter.urlInstagram" href="{{ dataFooter.urlInstagram}}" target="_blank">
        <img src="assets/images/instagram.svg" alt="image instagram">
      </a> 
      <a *ngIf="dataFooter.urlFacebook" href="{{ dataFooter.urlFacebook}}" target="_blank">
        <img src="assets/images/facebook.svg" alt="image facebook">
      </a>
      <a *ngIf="dataFooter.urlTwitter" href="{{ dataFooter.urlTwitter}}" target="_blank">
        <img src="assets/images/twitter.svg" alt="image twitter">
      </a>
    </figure>

  </div>
</footer>

<div class="container-copy-rigth">
  <p>© All rigths reserved 2021</p>
</div>