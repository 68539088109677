<app-header [dataHeader]="dataHeader" (OnShared)="onShared()"></app-header>

<main class="wrapper">
    <h2> Menú </h2>

    <ng-template ngFor let-i="index" let-c="count" let-data [ngForOf]="dataCard | async">
        <h3 class="subTitles {{ i === 0? '': 'mt-10'}}">{{ data.title }}</h3>
        <div class="card-grid">
            <app-card-menu *ngFor="let dataProduct of data.products" [dataProduct]="dataProduct" (OnClickButton)="actionAdd($event)"></app-card-menu>
        </div>
    </ng-template>
</main>

<app-footer [dataFooter]="dataFooter"></app-footer>

<app-floating-button *ngIf="totalShopping" orderPrice="{{ totalShopping | currency:'CLP':'symbol-narrow' }}" (floatAction)="actionShopping()"></app-floating-button>
