<div class="container-modal">
  <div class="col-imagen"></div>
  <div class="col-form">
    <div class="col-imagen-mobile"></div>
    <form [formGroup]="formShopping">
      <div class="container-form">
        <h2>Mi Pedido</h2>
        <div class="content-form">

          <mat-accordion class="example-headers-align">

            <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Carrito
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon>shopping_cart</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">

                <!-- Column -->
                <ng-container matColumnDef="quantity">
                  <th mat-header-cell *matHeaderCellDef> Cantidad </th>
                  <td mat-cell *matCellDef="let element">
                    <app-stepper-edit-quantity  [quantity]="element.quantity" (OnQuantity)="editCart($event, element.product.idProduct);"></app-stepper-edit-quantity>
                  </td>
                </ng-container>

                <!-- Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef> Nombre </th>
                  <td mat-cell *matCellDef="let element"> {{ element.product.type | slice:0:3 }}. {{ element.product.nameProduct }} </td>
                </ng-container>

                <!-- Column -->
                <!-- <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef> Precio </th>
                  <td mat-cell *matCellDef="let element"> {{ element.product.price | currency:'CLP':'symbol-narrow' }} </td>
                </ng-container> -->

                <!-- Column -->
                <ng-container matColumnDef="options">
                  <th mat-header-cell *matHeaderCellDef> Opciones </th>
                  <td mat-cell *matCellDef="let element">
                    <button mat-icon-button aria-label="Example icon-button with a menu" (click)="removeItem(element.product.idProduct)">
                      <mat-icon>delete</mat-icon>
                    </button>
                    <!-- <mat-menu #menu="matMenu" [hasBackdrop]="false">
                      <button mat-menu-item>
                        <mat-icon>delete</mat-icon>
                        <span>Eliminar</span>
                      </button>
                    </mat-menu> -->
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
              </table>

              <mat-action-row>
                <button mat-button color="warn" (click)="cleanCart()">Vaciar Carrito</button>
                <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Tipo de entrega
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon>delivery_dining</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <mat-radio-group aria-labelledby="example-radio-group-label" formControlName="typeDelivery"
                (change)="radioTypeDeliveryChange($event)">
                <mat-radio-button class="type-radio-button" value="Retiro">Retiro</mat-radio-button>
                <mat-radio-button class="type-radio-button" value="Delivery">Delivery</mat-radio-button>
              </mat-radio-group>

              <mat-form-field class="full-input" appearance="outline">
                <mat-label>Nombre</mat-label>
                <input matInput placeholder="Nombre quien recibe..." formControlName="name">
                <mat-error *ngIf="formShopping.get('name').invalid">Debe ingresar un nombre *</mat-error>
              </mat-form-field>

              <mat-form-field class="full-input" appearance="outline"
                *ngIf="formShopping.getRawValue().typeDelivery === 'Delivery'">
                <mat-label>Dirección</mat-label>
                <input matInput placeholder="Lugar a enviar..." formControlName="address">
                <mat-error *ngIf="formShopping.get('address').invalid">Debe ingresar una dirección *</mat-error>
              </mat-form-field>

              <mat-form-field appearance="outline" class="full-input">
                <mat-label>Nota adicionales</mat-label>
                <textarea placeholder="Aquí puedes escribir tus notas..." matInput rows="2" style="resize:none"
                  formControlName="description"></textarea>
              </mat-form-field>

              <mat-action-row>
                <button mat-button color="warn" (click)="prevStep()">Volver Carrito</button>
                <button mat-button color="primary" (click)="nextStep()">Siguiente</button>
              </mat-action-row>
            </mat-expansion-panel>

            <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Tipo de pago
                </mat-panel-title>
                <mat-panel-description>
                  <mat-icon>attach_money</mat-icon>
                </mat-panel-description>
              </mat-expansion-panel-header>


              <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group"
                formControlName="typePay" (change)="radioTypePayChange($event)">
                <mat-radio-button class="example-radio-button" value="Efectivo">Efectivo</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="Transferencia">Transferencia</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="Debito">Debito</mat-radio-button>
                <mat-radio-button class="example-radio-button" value="Credito">Credito</mat-radio-button>
              </mat-radio-group>

              <mat-form-field class="full-input" appearance="outline"
                *ngIf="formShopping.getRawValue().typePay === 'Efectivo'">
                <mat-label>¿Con cuánto pagas?</mat-label>
                <input matInput
                  placeholder="Ingresa un valor mayor (o igual) a {{ totalShopping | currency:'CLP':'symbol-narrow' }}"
                  formControlName="amountPay">
                  <mat-error *ngIf="formShopping.get('amountPay').invalid">Debe ingresar un valor valido *</mat-error>
              </mat-form-field>

              <!-- <mat-action-row>
                <button mat-button color="warn" (click)="prevStep()">Previous</button>
                <button mat-button color="primary" (click)="nextStep()">End</button>
              </mat-action-row> -->
            </mat-expansion-panel>

          </mat-accordion>
        </div>
        <div class="action-button">
          <div class="col-action">Total: {{ totalShopping | currency:'CLP':'symbol-narrow'}}</div>
          <div class="col-action" style="display:flex; flex-direction:row-reverse;">
            <button mat-button type="button" class="modal-buttom" style="margin-left: 10px;"
              (click)="send()">Enviar</button>
            <button mat-button type="button" class="modal-buttom button-cancel" color="warn"
              mat-dialog-close>Cancelar</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>