import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { Shopping } from 'src/app/core/models/shopping.interface';
import { AddShoppingService } from 'src/app/services/add-shopping.service';
import { ToastService } from 'src/app/services/toast.service';
import { WhatsappService } from 'src/app/services/whatsapp.service';

@Component({
  selector: 'app-modal-shopping',
  templateUrl: './modal-shopping.component.html',
  styleUrls: ['./modal-shopping.component.scss']
})
export class ModalShoppingComponent implements OnInit {

  displayedColumns: string[] = ['quantity', 'name', 'options'];
  dataSource: Shopping[] = [];
  totalShopping: number;

  public products: Array<Shopping> = [];
  public step = 1;

  public formShopping: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ModalShoppingComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private addShoppingService: AddShoppingService,
    private whatsappService: WhatsappService,
    private fb: FormBuilder,
    private toast: ToastService
  ) { }

  ngOnInit(): void {
    this.formShopping = this.buildForm();

    this.products = [...this.addShoppingService.getProducts()] as Shopping[];
    this.dataSource = [...this.addShoppingService.getProducts()] as Shopping[];
    this.totalShopping = this.products.reduce((acc, current) => current.total + acc , 0);

    this.formShopping.get('amountPay').setValidators([Validators.required, Validators.min(this.totalShopping)]);
  }

  buildForm(): FormGroup {
    return this.fb.group({
      typeDelivery: ['Retiro'],
      name: ['', [Validators.required]],
      address: [{ value: '', disabled: true }, [Validators.required]],
      description: [''],
      typePay: ['Efectivo'],
      amountPay: [{ value: '', disabled: false }, [Validators.required]]
    });
  }

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  anularPedido(): void {
    this.addShoppingService.clearCart();
  }

  radioTypeDeliveryChange(event: MatRadioChange): void {
    if (event.value === 'Delivery') {
      this.formShopping.get('address').enable();
    } else {
      this.formShopping.get('address').disable();
    }
  }


  radioTypePayChange(event: MatRadioChange): void {
    if (event.value === 'Efectivo') {
      this.formShopping.get('amountPay').enable();
    } else {
      this.formShopping.get('amountPay').disable();
    }
  }

  send(): void {
    if (this.formShopping.valid && this.products.length > 0) {
      const formValue = this.formShopping.getRawValue();
      const text = this.whatsappService.builderMessage(this.products, formValue);
      const link = `https://api.whatsapp.com/send?phone=56933201655&text=${this.whatsappService.convertUrl(text)}`;

      this.addShoppingService.clearCart();
      this.dialogRef.close(true);
      window.open(link, '_blank');
    } else {
      this.toast.error('Debes ingresar los datos para poder continuar');
    }
  }

  cleanCart() {
    this.addShoppingService.clearCart();
    this.products = [...this.addShoppingService.getProducts()] as Shopping[];
    this.dataSource = [...this.addShoppingService.getProducts()] as Shopping[];
    this.totalShopping = this.products.reduce((acc, current) => current.total + acc , 0);
    this.formShopping.get('amountPay').setValidators([Validators.required, Validators.min(this.totalShopping)]);
  }

  editCart(quantity: number, id: number): void {
    this.addShoppingService.editCart(quantity, id);
    this.products = [...this.addShoppingService.getProducts()] as Shopping[];
    this.dataSource = [...this.addShoppingService.getProducts()] as Shopping[];
    this.totalShopping = this.products.reduce((acc, current) => current.total + acc , 0);
    this.formShopping.get('amountPay').setValidators([Validators.required, Validators.min(this.totalShopping)]);
  }

  removeItem(id: number): void {
    this.addShoppingService.removeCart(id);
    this.products = [...this.addShoppingService.getProducts()] as Shopping[];
    this.dataSource = [...this.addShoppingService.getProducts()] as Shopping[];
    this.totalShopping = this.products.reduce((acc, current) => current.total + acc , 0);
    this.formShopping.get('amountPay').setValidators([Validators.required, Validators.min(this.totalShopping)]);
  }
}
